import revive_payload_client_4sVQNw7RlN from "/var/www/apps/korner.ng/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/apps/korner.ng/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/apps/korner.ng/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/apps/korner.ng/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/apps/korner.ng/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/apps/korner.ng/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/apps/korner.ng/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/apps/korner.ng/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/var/www/apps/korner.ng/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/apps/korner.ng/.nuxt/sentry-client-config.mjs";
import plugin_gab9g3XuEx from "/var/www/apps/korner.ng/node_modules/nuxt-aos/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/var/www/apps/korner.ng/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/var/www/apps/korner.ng/plugins/axios.ts";
import gsap_qE0fizvEy0 from "/var/www/apps/korner.ng/plugins/gsap.ts";
import helpers_mgWmzbOuLB from "/var/www/apps/korner.ng/plugins/helpers.ts";
import vuetify_7h9QAQEssH from "/var/www/apps/korner.ng/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_gab9g3XuEx,
  chunk_reload_client_UciE0i6zes,
  axios_QMFgzss1s4,
  gsap_qE0fizvEy0,
  helpers_mgWmzbOuLB,
  vuetify_7h9QAQEssH
]