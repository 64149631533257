// composables/eventBus.ts
import { reactive, watch, toRefs } from "vue";

interface EventBus {
  [key: string]: any;
}

interface EventWatchers {
  [key: string]: (() => void)[];
}

const eventBus = reactive<EventBus>({});
const eventWatchers: EventWatchers = {};

export function useEventBus() {
  return {
    ...toRefs(eventBus),
    emit(event: string, payload: any = null) {
      let load = null;
      const type = typeof payload;
      switch (type) {
        case "string":
          event = payload;
          break;

        case "number":
          event = payload;
          break;

        case "boolean":
          event = payload;
          break;

        case "object":
          if (payload === null) {
            load = Math.random();
          } else if (Array.isArray(payload)) {
            load = [...payload];
          } else {
            load = { ...payload };
          }
          break;

        case "undefined":
          load = Math.random();
          break;

        case "function":
          load = payload();
          break;

        case "symbol":
          load = payload;
          break;

        case "bigint":
          load = payload;
          break;

        default:
          load = payload;
          break;
      }
      eventBus[event] = load;
    },
    on(event: string, callback: (payload: any) => void) {
      // Watch for changes on the event and trigger the callback
      const stop = watch(() => eventBus[event], callback);

      // Register the watcher function for the event
      if (!eventWatchers[event]) {
        eventWatchers[event] = [];
      }
      eventWatchers[event].push(stop);

      // Return the function to stop watching the event
      return stop;
    },
    off(event: string) {
      // Stop all watchers for the given event
      if (eventWatchers[event]) {
        eventWatchers[event].forEach((stop) => stop());
        // Clear the array of watchers for the event
        eventWatchers[event] = [];
      }
    },
  };
}
